<template>
    <transition>
        <div ref="contentsWrap" class="container contents-wrap" id="contentsWrap">
            <div class="container-bottom-box content-info-box01">
                진행 상태 및 처리 결과는 일괄 업로드 이력 메뉴에서 확인하세요.
            </div>
        </div>
    </transition>
</template>

<script>
    import { DxTooltip } from 'devextreme-vue/tooltip';
    import { DxPopover } from 'devextreme-vue/popover';
    import { DxDataGrid, DxSelection, DxScrolling, DxColumn ,DxFilterRow, DxOperationDescriptions, DxFilterPanel, DxHeaderFilter } from 'devextreme-vue/data-grid';
    import { DxTreeList } from 'devextreme-vue/tree-list';
    import CheckBox from "devextreme/ui/check_box";
    import { DxTextBox } from 'devextreme-vue/text-box';
    import DxTextArea from 'devextreme-vue/text-area';
    import { DxButton } from 'devextreme-vue/button';
    
    import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
    import DxSwitch from 'devextreme-vue/switch';
    import { DxSelectBox } from 'devextreme-vue/select-box';

    // file drag & drop
    import { DxFileUploader } from 'devextreme-vue/file-uploader';

    import { isSuccess } from "@/plugins/common-lib";

    export default {
        components: {
            DxTooltip,
            DxPopover,
            DxDataGrid,
            DxTreeList,
            DxSelection,
            DxScrolling,
            DxColumn,
            DxFilterRow,
            DxOperationDescriptions,
            DxFilterPanel,
            DxHeaderFilter,
            CheckBox,
            DxTextBox,
            DxTextArea,
            DxButton,

            DxValidator, 
            DxRequiredRule,
            DxSwitch,
            DxSelectBox,
            DxFileUploader,

        },
        props:{
            contentData: Object,
            iconData: Array,
        },
        watch: {
        },
        data() {
            return {
                config:{
                    stylingMode: 'outlined',
                }, 
            }
        },
        computed: {
            /** @description: props 리스트에서 선택한 데이터 */
            selectedRowsData(){
                return this.contentData.selectedRowsData;
            },
            /** @description: props 리스트에서 선택한 데이터 */
            getApiActionNm(){
                return this.contentData.apiActionNm;
            },
        },
        methods: {
            /** @description : 라이프사이클 creaed시 호출되는 메서드 */
            createdData(){


            },
            /** @description : 라이프사이클 mounted시 호출되는 메서드 */
            mountedData(){
                this.$_eventbus.$on('ModalProgressUser:onSaveData', async(e, resolve) => {
                    this.$router.push('/setting/history/bulkwork-upload');
                });
            },
            /** @description : 라이프사이클 destroyed시 호출되는 메서드 */
            destroyedData(){
                this.$_eventbus.$off('ModalProgressUser:onSaveData');
            },
        },
        created() {
            this.createdData();
        },
        mounted() {
            this.mountedData();
        },
        destroyed() {
            this.destroyedData();
        },
    }
</script>

<style scoped>
    .content-info-box01 { margin-top: 10px; }
</style>
<style >
    

</style>